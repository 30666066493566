$jd-primary: hsl(182, 92%, 61%);

$family-primary: 'Dosis';
$turquoise: hsl(182, 92%, 61%);

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/grid/_all.sass";
@import "bulma/sass/elements/_all.sass";
@import "bulma/sass/layout/_all.sass";
@import "bulma/sass/components/_all.sass";
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$bluish: #509e97;
$clear: #ffffff00;
#root {
  overflow: hidden;
  display: flex;
  // background: $bluish;
}


.products .column, .products .column .product {
  // background-color: $bluish !important;
}
.content {
  width: 100%;
  // background: $bluish;

  // margin÷Left: 0, 
  // margin-top: 5vh !important;
  // margin-bottom: 10vh !important;
  padding: 0 1em !important;
  margin: 10vh .5em 10vh .5em !important;
}

#address-search {
  z-index: 50;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: absolute;
}

.navbar-brand {
  justify-content: space-between;
  align-items: center;
}
.back-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#navbar-cart {
  justify-content: space-between;
  background: $bluish; 
  width: 100%;
  min-height: unset;
  display: flex;
}

#navbar-cart-add {
  position: fixed;
  bottom:0;
  width: 100%;
  background: $bluish; 
  justify-content: center;
  user-select: none;
}

#navbar-checkout {
  justify-content: space-between;
  background: $bluish; 
  width: 100%;
  display: flex;
  justify-content: center;
}

#navbar-cart p, #navbar-checkout p, #navbar-cart-add p  {
  color: white;
}

.navbar {
  justify-content: center;
  align-items: center;
  // position: fixed;
  // right: 0;
  // top: 0;
  // background: $bluish;
  // width: 100%
}
.navbar .is-fixed-top {
  background: white;
}
.navbar-menu {
  white-space: nowrap;
  width: min-content;
  transition: right .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
  box-shadow: none;
  background: $bluish;
}

.navbar-brand {
  // align-items: center;
  // margin-right: .75em;
}

.navbar-burger p {
  user-select: none;
}

#tab-nav {
//   display: flex;
  justify-content: center;
}
// a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:hover, .navbar-link.is-active {
//   background-color: $bluish !important;
//   background: $bluish !important;
//   user-select: none;
// }

#checkout-address p {
  margin-bottom: 0;
}

.progress.is-small {
  height: 0.25rem !important;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0px;
  z-index: 50;
}
.navbar-brand .navbar-item a {
  flex-direction: column;
  padding: 0.5rem 0.45rem;
}

#navbar-menu-mobile {
  right: -500px;
  position: absolute;
}

#navbar-menu-mobile {
  cursor: pointer;
}

.logo {
  cursor: pointer;
  color: $bluish !important;
  user-select: none !important;

}
.logo:hover {
  background: white;
  color: $bluish !important;
}
.logo:active {
  background: white;
  color: $bluish !important;
}

.navbar-menu-offset {
  right:  0!important;
}

.navbar-menu-offset .button {
  background: $clear;
  border-color: $clear;

}

a.navbar-item:hover {
  color: $bluish !important;
}

.price-text {
  font-family: 'Roboto', sans-serif;
  color: #4D4730;
}

.product-list-image {

  max-height: 500px;
  // mix-blend-mode: multiply;
}

#firebaseui-auth-container {
  height: 100vh !important;
  display: flex;
  align-items: center;
  width: 100%;
  // margin-top: 10vh;
}
// @import "bulma/sass/base/_all.sass"; 

// body {
//   text-align: center;
//   margin: 0;
// }

// .header {
//   height: 5vh;
//   // background: blue; 
// }

// .header .column {
//   margin: 2em 0.5em 0.5em 0.5em !important;
// }

// .header .is-pulled-right .icon {
//   margin-left: 1em;
// }

// .App-logo {
//   animation: App-logo-spin infinite 20s linear;
//   height: 40vmin;
//   pointer-events: none;
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }
